









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OModuleConfig: () =>
      import(
        "@/components/organisms/event/modules/registrationModule/o-module-config.vue"
      ),
  },
  setup() {
    return {};
  },
});
